/*** Import / Initialisation des variables ***/

	/* Conf */
	@import '../global/settings';

/********************************************************************************/
/********************************** SOCIAL WALL *********************************/
/********************************************************************************/
		
	#home #social_wall {margin-bottom:3rem;}
	
	/*** BARRE DES ACCES RS ***/
		#sw_links {padding-top:1.5rem; padding-bottom:1.5rem; position:relative;}
		#sw_links::before {content:''; position:absolute; top:0; left:1.5rem; width:calc(100% - 3rem); height:100%; z-index:-1; border-top:0.1rem solid $yellow; border-bottom:0.1rem solid $yellow;}
		#sw_links p {font-size:2em; font-family:$lato_bold; margin:0;}
		#sw_links p a {display:flex; align-items:center; box-shadow:none !important;}
		#sw_links p a::before {content:''; display:inline-block; width:4.2rem; height:4.2rem; background-repeat:no-repeat; background-position:center center; background-size:contain; vertical-align:middle; margin-right:1.2rem; transition:0.3s ease;}
		#sw_links .facebook p a::before {@include facebook($cim_black);}
		#sw_links .instagram p a::before {@include instagram($cim_black);}

		#sw_links p a:hover, #sw_links p a:focus {text-decoration:underline;}
		
	/*** FLUX ***/
		#sw_flux {position:relative; margin-top:3rem; margin-bottom:-3rem;}

		/*** ITEMS ***/

			/*** AFFICHAGE DES ITEMS ***/
				#sw_flux .sw_item {position:static; margin-bottom:3rem; opacity:0; transform:scale(0.9);}
				#sw_flux .sw_item:nth-child(n + 7) {display:none;}
				#sw_flux .sw_item.mui-enter-active {opacity:1; transform:scale(1); transition:transform 0.2s ease-out, opacity 0.2s ease-out;}
				
				#sw_flux .sw_item .sw_content {position:relative; overflow:hidden;}
				#sw_flux .sw_item .sw_content a::after {display:none;}

				/*** TEXTE / DESCRIPTION ***/
					#sw_flux .sw_item .sw_txt {position:absolute; bottom:0; right:0; width:100%; min-height:50%; max-height:100%; padding:1.5rem; transition:0.5s ease-in-out; z-index:1;}
					#sw_flux .sw_item .sw_txt:empty {height:0;}
					#sw_flux .sw_item .sw_txt p {color:$white; font-size:1.4em; line-height:1.4em; margin:0; position:relative; z-index:2; transition:0.3s ease;}
					#sw_flux .sw_item .sw_content:hover .sw_txt p {color:$cim_black;}
					
					#sw_flux .sw_item .sw_txt p a {color:inherit; display:inline-block; border:none; box-shadow:none !important; transition:0s;}
					#sw_flux .sw_item .sw_content:not(:hover) .sw_txt p a:hover, #sw_flux .sw_item .sw_content:not(:hover) .sw_txt p a:focus {color:$yellow;}
					#sw_flux .sw_item .sw_txt .sw_user {position:relative; z-index:3; font-size:1.8em; word-break:break-word;}
					#sw_flux .sw_item .sw_txt .sw_user a {font-family:$lato_bold; margin-bottom:0.5rem;}
					#sw_flux .sw_item .sw_txt .sw_desc {}

				/*** MINIATURES ***/
					#sw_flux .sw_item .sw_img {display:block; width:100%; overflow:hidden; background:$cim_black; border:0; padding:0 0 100% 0; box-shadow:none !important; transition:0.5s ease;}
					#sw_flux .sw_item .sw_img::before {content:''; position:absolute; top:0; left:0; width:100%; height:100%; border:0.4rem solid transparent; z-index:2; transition:0.3s ease;}
					#sw_flux .sw_item .sw_img::after {display:none;}
					#sw_flux .sw_item .sw_img:hover {background:$yellow;}
					#sw_flux .sw_item .sw_img:focus::before {border-color:$yellow;}
					
					#sw_flux .sw_item .sw_img img {position:absolute; top:0; left:0; width:100%; height:50%; transform:scale(1.1); object-fit:cover; transition:1s ease-in-out;}
					#sw_flux .sw_item .sw_img:hover img, #sw_flux .sw_item .sw_img:focus img {transform:scale(1); transition:all 1s ease-in-out, opacity 0.5s ease-in-out 1s; opacity:0.6;}
					
				/*** MODE SANS IMAGES ***/
					#sw_flux .sw_item.no_img .sw_txt {min-height:100%; max-height:100%;}
					
				/*** MODE SANS TEXTE ***/
					#sw_flux .sw_item.no_txt .sw_txt {display:none;}
					#sw_flux .sw_item.no_txt .sw_img img {height:100%;}
					
				
/********************************************************************************/
/******************************** RESPONSIVE ************************************/
/********************************************************************************/

	@media screen and #{breakpoint(large down)} {}
	
	@media screen and #{breakpoint(medium down)} {
		
		#home #social_wall {margin-bottom:0;}
		
		/*** FLUX ***/
			#sw_flux {justify-content:center;}
	
			/*** ITEMS ***/
				/*** AFFICHAGE DES ITEMS ***/
					#sw_flux .sw_item {min-width:25rem;}
					#sw_flux .sw_item .sw_txt .sw_desc {display:-webkit-box; -webkit-line-clamp:3; -webkit-box-orient:vertical; overflow:hidden;}
				
			/*** AFFICHAGE DES ITEMS NON FILTRES ***/
				/* V1 : GRID (cf. Roissy) */
					#sw_flux.unfiltered .sw_item {position:static; margin-top:0 !important;}
	}
	
	@media screen and #{breakpoint(small down)} {
		
		/*** BARRE DE FILTRES ***/
			#sw_links {padding-bottom:0;}
			#sw_links p {margin-bottom:1.5rem; font-size:1.8em;}
			#sw_links p a::before {width:3rem; height:3rem;}
		
		
		/*** FLUX ***/
			#sw_flux {}
		
			/*** ITEMS ***/
				#sw_flux .sw_item {max-width:31rem !important; margin-right:auto !important; margin-left:auto !important; margin-bottom:1.5rem;}
				#sw_flux .sw_item .sw_content {min-height:10rem;}
		
				/*** TEXTE / DESCRIPTION ***/
					#sw_flux .sw_item .sw_txt {position:relative; background:transparentize($cim_black, 0.2); max-height:none; min-height:10rem;}
					#sw_flux .sw_item .sw_txt p {color:$cim_white !important;}

				/*** MINIATURES ***/
					#sw_flux .sw_item .sw_img {position:absolute; top:0; left:0; width:100%; height:100%; padding-bottom:0;}
					#sw_flux .sw_item .sw_img img {height:100%;}
	}
